import React, { useRef, useState, useEffect } from 'react';
import { Modal, Divider, message } from 'antd';
import moment from 'moment';

// import StsUploadFile from '../../upload_files/sts_upload_file';
import { StsUploadImagesList } from '../../upload_files/sts_upload_files_list';
import { SpecialGoodsRequestPut, SpecialGoodsRequestDelete  } from './special_goods_requests';
import { CustomForm } from '../../base_components/form_component';
import { GoodsOptModal } from '../../modals/goods_opt';

import {
    formKeys,
    formDisabledKeys,
    formNotShowKeys,
    formKeysLabelsObj,
    formKeySelectComponentsObj,
    formKeysHandleButtonObj,
    // formInitialObj,
    formRulesList,
    imageKeyNames,
    maxImagesNum
} from './special_goods_columns_label_config';

// 编辑 SpecialGoods

const UpdateSpecialGoods = ({ visible, setVisible, oldObj, reloadListCount, setReloadListCount }) => {

    const specialGoodsImageObj = useRef({});

    const [putRequestCount, setPutRequestCount] = useState(0);
    const reqObject = useRef({});
    const putResponseDataHandle = (respObj) => {
        if (Object.keys(respObj).includes('code') && respObj.code === 1000) {
            message.success('修改成功');
            setReloadListCount(reloadListCount + 1);
            setVisible(0);
        } else {
            message.error('网络错误...');
        }
    }

    const [deleteRequestCount, setDeleteRequestCount] = useState(0);
    const deleteSpecialGoods = () => {
        // 删除当前对象
        setDeleteRequestCount(deleteRequestCount + 1);
        setReloadListCount(reloadListCount + 1);
    };

    // 选择 modal
    const [optModalState, setOptModalState] = useState(0);
    const [updateFormObj, setUpdateFormObj] = useState({});
    const selectGoodsHandle = (goodsObj) => {
        // 选择商品操作
        console.log(goodsObj);
        setUpdateFormObj({
            goods_id: goodsObj.id,
        });
        setOptModalState(0);
    };

    useEffect(() => {
        if (visible > 0) {
            console.log(oldObj);
            setUpdateFormObj({id: -1});
            let endTimeStamp = oldObj.end_time ? parseInt(oldObj.end_time) : 0;
            if (endTimeStamp < 10000000000) {
                endTimeStamp = parseInt(endTimeStamp * 1000);
            }
            const endTimeMoment = moment(endTimeStamp);
            setUpdateFormObj({ ...oldObj, end_time: endTimeMoment });  // 给表格赋值
        }
    }, [visible, oldObj]);

    const specialGoodsImageUploaded = (imagesObj) => {
        // 轮播图上传后操作
        specialGoodsImageObj.current = imagesObj;
        console.log(imagesObj);
    }

    const onFinish = (values) => {
        // 提交表单
        let upObj = { ...values, ...specialGoodsImageObj.current };
        console.log(upObj);
        // 时间转时间戳
        upObj.end_time = parseInt(moment.unix(upObj.end_time).unix() / 1000);  // 时间转时间戳
        console.log(upObj.end_time);
        reqObject.current = upObj;
        setPutRequestCount(putRequestCount + 1);
    }

    return (
        <Modal visible={visible === 2}
            title="SpecialGoods"
            closable={false}
            footer={null}
        >
            <div style={{width: '100%', height: 'auto', textAlign: 'center'}}>
                <StsUploadImagesList
                    maxImagesNum={maxImagesNum}
                    imageKeyName={imageKeyNames[0]}
                    oldObj={oldObj}
                    onChange={specialGoodsImageUploaded}
                ></StsUploadImagesList>
            </div>
            <Divider></Divider>

            <CustomForm
                columns={formKeys}
                disabledKeys={formDisabledKeys}
                notShowKeys={formNotShowKeys}
                formKeyLabelObj={formKeysLabelsObj}
                formKeySelectComponentsObj={formKeySelectComponentsObj}
                formKeysItemButtonObj={formKeysHandleButtonObj(setOptModalState)}
                onFinish={onFinish}
                cancelHandle={() => setVisible(0)}
                showDeleteButton={true}
                deleteHandle={deleteSpecialGoods}
                updateFormObj={updateFormObj}
                rulesObj={formRulesList}
            ></CustomForm>

            <GoodsOptModal
                modalState={optModalState === 1}
                setModalState={setOptModalState}
                selectHandle={selectGoodsHandle}
            ></GoodsOptModal>

            <SpecialGoodsRequestPut
                requestCount={putRequestCount}
                reqObject={reqObject.current}
                responseDataHandle={putResponseDataHandle}
                id={oldObj.id}></SpecialGoodsRequestPut>
            <SpecialGoodsRequestDelete
                requestCount={deleteRequestCount}
                responseDataHandle={(respObj) => setVisible(0)}
                id={oldObj.id}></SpecialGoodsRequestDelete>
        </Modal>
    )
}

UpdateSpecialGoods.defaultProps = {
    visible: false,
    setVisible: (val) => console.log(val),
    oldObj: {}
}

export default UpdateSpecialGoods;
