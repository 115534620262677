import React, { useState, useRef } from 'react';
// import { navigate, Link } from 'gatsby';
import { Button, Row, Col, Space, Tooltip, message } from 'antd';
import { ReloadOutlined, SearchOutlined, LeftOutlined } from '@ant-design/icons';

// import CreateSpecialGoods from './create_special_goods';
// import SpecialGoodsQueryDrawerForm from './special_goods_query_form';
import SpecialGoodsList from './special_goods_list';
import { GoodsOptModal } from '../../modals/goods_opt';
import { SpecialGoodsRequestPut, updateSpecialGoods } from './special_goods_requests';
// SpecialGoods 入口组件

const SpecialGoodsComponent = ({ selectButtonOnClick = null }) => {
  const reqParams = useRef({ page: 0 });

  const [reloadListCount, setReloadListCount] = useState(1);

  // const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(0);

  const selectSpecialGoodsHandle = (goodsObj) => {
    // 设置商品为今日特价商品
    setModalVisible(0);
    // console.log(goodsObj.id);
    updateSpecialGoods(goodsObj.id).then(resp => {
      console.log(resp);
      message.success(`设置 ${resp.name} 为今日特价商品成功!`);
      setReloadListCount(reloadListCount + 1);    // 重新读取特价商品列表
    }).catch(err => {
      console.log(err);
      message.error(`设置今日特价商品(ID=${goodsObj.id})失败...`)
    })
  }

  const initReloadList = () => {
    // 刷新列表, 删除所有查询条件
    reqParams.current = { page: 0 };
    setReloadListCount(reloadListCount + 1);
  }

  return (
    <div>
      <Row>
        <Col span={14}>
          <Space>
            <h4>SpecialGoods</h4>
            <Tooltip title="刷新">
              <Button onClick={initReloadList} shape="circle" icon={<ReloadOutlined />}></Button>
            </Tooltip>
          </Space>
        </Col>
        <Col span={10}>
          <div style={{ width: '100%', height: 'auto' }}>
            <Button style={{ float: 'right' }} type="link" onClick={() => setModalVisible(9)}>增加今日特价商品</Button>
          </div>
        </Col>
      </Row>

      <SpecialGoodsList
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
        requestParams={reqParams.current}
        selectButtonOnClick={selectButtonOnClick}
      ></SpecialGoodsList>

      <GoodsOptModal
        visible={modalVisible}
        setVisible={setModalVisible}
        visibleNum={9}
        selectHandle={selectSpecialGoodsHandle}
      ></GoodsOptModal>
    </div>
  )
};

export default SpecialGoodsComponent;
