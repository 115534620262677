import React from 'react';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { CustomSelect } from '../../base_components/select_component';
// import { expressCompanyRequestGet } from './custom_select_requests';
import { customRanderItem } from '../../base_components/lists/custom_list';

// SpecialGoods 列 配置

// ~~~~~~ 表单列, 需添加 ~~~~~~
const formKeys = [
    "id", "name", "special_sale",
];

// ~~~~~~ 表单不能编辑列, 根据实际添加 ~~~~~~
const formDisabledKeys = [
    "id", "name",];

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = [
    "image",];

// ~~~~~~ 表单列名称 label, 需添加 ~~~~~~
const formKeysLabelsObj = {
    "id": "ID", "name": "品名", "channel_id": "频道", "categories": "分类", "sort_order": "排序顺序", "freight_template_id": "快递模版ID",
    "freight_type": "快递类别", "goods_brief": "商品简介", "goods_number": "商品数量", "goods_unit": "单位", "has_done": "有现货", "has_gallery": "已推广",
    "is_delete": "已下架", "is_index": "在序列", "is_new": "是新品", "is_on_sale": "已上架", "keywords": "关键词", "cost_price": "成本价",
    "min_cost_price": "最低成本价", "min_retail_price": "最低零售价", "retail_price": "零售价", "sell_volume": "销售数量", "https_pic": "链接图",
    "goods_desc": "详情页", "list_pic": "主图", "home_pic1": "主图1", "home_pic2": "主图2", "home_pic3": "主图3", "home_pic4": "主图4",
    "home_pic5": "主图5", "home_pic6": "主图6", "home_pic7": "主图7", "home_pic8": "主图8", "home_pic9": "主图9", "goods_video": "商品视频",
    "handle_staff_id": "操作员ID", "add_time": "创建时间", "update_time": "更新时间", "explains": "说明", "special_sale": "今日特价商品",
};

//  ~~~~~~ 选择框, 根据实际添加 ~~~~~~
// const SelectBannerEnabled = (<CustomSelect
//     options={[{ value: 0, label: '不可编辑' }, { value: 1, label: '可编辑' }]} />);
// const SelectBannerLinkType = (<CustomSelect
//     options={[{ value: 0, label: '无效' }, { value: 1, label: '有效' }]} />);
const SelectIsDelete = (<CustomSelect 
    options={[{ value: 0, label: '否'}, { value: 1, label: '是'}]} />)
// const SelectEndTime = (<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />);
const formKeySelectComponentsObj = {
    // enabled: SelectBannerEnabled,
    // end_time: SelectEndTime,
    // is_delete: SelectIsDelete,
    // link_type: SelectBannerLinkType,
    special_sale: SelectIsDelete
};

// ~~~~~~ 表单自定义操作按钮, 根据实际添加 ~~~~~~
const formKeysHandleButtonObj = (setOptModalState) => {
    return {
        goods_id: {buttonTips: '选择商品', clickMethod: () => setOptModalState(1)},
    };
};

// ~~~~~~ 表单是图片列, 根据实际添加(废弃, 改用 imageKeyNames) ~~~~~~
// const formKeysIsImages = [];

// ~~~~~~ 查询表单 get 请求条件keys, 根据实际添加 ~~~~~~
const formGetRequestParamsKeys = ["id", ];

//  ~~~~~~ 表单初始化值, 根据实际添加 ~~~~~~
const formInitialObj = {
    "id": 0, "enabled": 0, "goods_id": 0, "end_time": "", "link": "", "link_type": 0,
    "explains": "", "add_time": "", "update_time": ""
}

// ~~~~~~ 表单值规则, 根据实际添加 ~~~~~~
// formRulesList = {formKey: [{require: true, message: 'Please input Password!',}, ]};
const formRulesList = {"password": [{required: true, message: 'Please input Password!',}, ], }

// ~~~~~~ 图片列名称, 根据实际修改(多张图片去掉最后序号数字) ~~~~~~
const imageKeyNames = ['home_pic'];

// ~~~~~~ 图片总数量, 默认设置为1, 根据实际修改 ~~~~~~
const maxImagesNum = 9;

// ~~~~~~ api请求url apiLists, 根据实际修改 ~~~~~~
const apiLists = ['goods-admin/v1/specialgoods', 'goods-admin/v1/goods', ];

const actionButtons = (item, setVisible, setSelectedObj, selectButtonOnClick) => {
    const onClickInfo = () => {
        setSelectedObj(item);
        setVisible(2);
    }
    const btnList = [
        <Button onClick={onClickInfo} type="link">详情</Button>,
    ];
    if (selectButtonOnClick) {
        btnList.push(<Button onClick={() => selectButtonOnClick(item)} type="link">选择</Button>)
    }
    return btnList;
}
// 修改 ~~~~~~~ item keys ~~~~~~;
const renderItem = (item, setSelectedObj, selectButtonOnClick, setVisible, visible) => {
    // 必须 每一行渲染 的组件, 修改 ~~~~~~~ item keys ~~~~~~;
    const textsContent = (
        <div>
            <p>{`商品ID: ${item.id}`}</p>
            <p>{`是今日特价商品: ${item.special_sale > 0 ? '是' : '否'}`}</p>
        </div>
    )
    const updateItemObj = {
        id: item.id,
        actions: actionButtons(item, setVisible, setSelectedObj, selectButtonOnClick),
        img: item.home_pic1_url,
        avatar: '',
        title: `商品标题:  ${item.name};`,
        content: textsContent,
    };
    return customRanderItem(updateItemObj, 180);
};

const judgeHasMore = (resp) => {
    if (Object.keys(resp).includes('next')
        && typeof resp.next === 'string'
        && resp.next.length > 5) {
        return true;
    }
    return false;
};

export {
    formKeys,
    formDisabledKeys,
    formNotShowKeys,
    formKeysLabelsObj,
    formKeySelectComponentsObj,
    formKeysHandleButtonObj,
    // formKeysIsImages,
    formGetRequestParamsKeys,
    formInitialObj,
    formRulesList,
    imageKeyNames,
    maxImagesNum,
    apiLists,
    renderItem,
    judgeHasMore
};
