import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import SpecialGoodsComponent from '../components/wx_store/special_goods';

const SpecialGoodsManagePage = () => (
  <SiteLayout>
    <SEO title="WX SpecialGoods" />
    <SpecialGoodsComponent></SpecialGoodsComponent>
  </SiteLayout>
)

export default SpecialGoodsManagePage;
