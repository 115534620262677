import React, { useState, useEffect, useRef } from 'react';

import { CustomList } from '../../base_components/lists/custom_list';
import { SpecialGoodsRequestGet } from './special_goods_requests';
import UpdateSpecialGoods from './update_special_goods';
import { renderItem, judgeHasMore } from './special_goods_columns_label_config';
import { LoadMoreButton } from '../../base_components/load_more_button_conpoment';

// 列表编辑 页面

const SpecialGoodsList = ({ reloadListCount, setReloadListCount, requestParams, selectButtonOnClick }) => {

    const [visible, setVisible] = useState(0);

    const [hasMoreState, setHasMoreState] = useState(true);
    const [loading, setLoading] = useState(false);
    // 设置为true 开始request请求, 解决useState异步
    const [requesting, setRequesting] = useState(false);
    const reqParams = useRef({ page: 0 });

    const [specialGoodsData, setSpecialGoodsData] = useState([]);
    const [specialGoodsRequestGetCount, setSpecialGoodsRequestGetCount] = useState(0);

    const [selectedObj, setSelectedObj] = useState({});

    useEffect(() => {
        if (hasMoreState && !loading && requesting) {
            // 使用useEffect 触发request 解决 useState 异步
            loadMore();
            setRequesting(false);
        }
    }, [requesting, hasMoreState, loading])

    useEffect(() => {
        // 加载、更新或创建数据后 重新读取列表
        reqParams.current = { ...requestParams };
        reloadListsHandle();
    }, [reloadListCount]);

    const loadMore = () => {
        // 读取更多列表
        if (hasMoreState && !loading) {
            setLoading(true);
            setHasMoreState(false);
            const current_page = Number.isInteger(reqParams.current.page) ? reqParams.current.page + 1 : 1;
            reqParams.current = { ...reqParams.current, page: current_page };
            console.log(reqParams.current);
            setSpecialGoodsRequestGetCount(specialGoodsRequestGetCount + 1);
        } else {
            console.log('Error state: hasMore is ', hasMoreState, ', loading is ', loading);
        }
    };

    const reloadListsHandle = () => {
        // 重新读取数据
        reqParams.current = { ...reqParams.current, page: 0 };
        // console.log(reqParams.current);
        setSpecialGoodsData([]);
        setHasMoreState(true);
        setLoading(false);
        setRequesting(true);    // useEffect 触发request请求，解决useState异步
    };

    const specialGoodsGetResponse = (resp) => {
        // console.log('resp process...');
        setLoading(false);
        if (Object.keys(resp).includes('code')
            && resp.code === 1000
            && Object.keys(resp).includes('results')
        ) {
            setSpecialGoodsData(specialGoodsData.concat(resp.results));
            setHasMoreState(judgeHasMore(resp));   // 判断是否还有更多
        } else {
            console.log('Error: get data...');
        }
    };

    return (
        <div>
            <SpecialGoodsRequestGet requestCount={specialGoodsRequestGetCount}
                reqParams={reqParams.current}
                responseDataHandle={specialGoodsGetResponse}></SpecialGoodsRequestGet>
            <CustomList
                header="SpecialGoods List"
                listData={specialGoodsData}
                visible={visible}
                setVisible={setVisible}
                randerItem={renderItem}
                setSelectedItem={setSelectedObj}
                selectButtonOnClick={selectButtonOnClick}
            ></CustomList>

            <UpdateSpecialGoods
                visible={visible}
                setVisible={setVisible}
                oldObj={selectedObj}
                reloadListCount={reloadListCount}
                setReloadListCount={setReloadListCount}
            ></UpdateSpecialGoods>

            <LoadMoreButton hasMore={hasMoreState} onClick={loadMore}></LoadMoreButton>
        </div>
    );
}

SpecialGoodsList.defaultProps = {
    reloadListCount: 1,
    setReloadListCount: (addedNum) => { console.log(addedNum); },
    requestParams: { page: 0 },
    selectButtonOnClick: null,
}

export default SpecialGoodsList;
