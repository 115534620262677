import React from 'react';

import AxiosRequest from '../../apis';
import { sendAxiosRequest } from '../../apis/sendRequest.js';

import { apiLists } from './special_goods_columns_label_config';

// SpecialGoods 的request请求

const SpecialGoodsRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // SpecialGoods Get请求
  const api = apiLists[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
SpecialGoodsRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const SpecialGoodsRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // SpecialGoods post请求
  const api = [].concat(apiLists)[0];
  const method = 'post';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
SpecialGoodsRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
}

const SpecialGoodsRequestPut = ({ requestCount, reqObject, responseDataHandle, id }) => {
  // SpecialGoods put请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'put';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
SpecialGoodsRequestPut.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

const SpecialGoodsRequestDelete = ({ requestCount, responseDataHandle, id }) => {
  // SpecialGoods delete请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'delete';
  const reqParams = {};
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
SpecialGoodsRequestDelete.defaultProps = {
  requestCount: 0,
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

const updateSpecialGoods = (goodsId) => {
  // 更新为今日特价商品
  return new Promise((resolve, reject) => {
    let api = [].concat(apiLists)[0] + `/${goodsId}`;
    let method = 'put';
    let reqParams = {};
    let reqObject = {'special_sale': 1};
    sendAxiosRequest(api, method, reqParams, reqObject)
      .then((response) => {
          // console.log('Success request:', response);
          if (response.code === 1000) {
            resolve(response);
          } else {
            reject(response);
          }
      })
      .catch((err) => {
        console.log(err);  
        reject(err);
      });
  })
}

export {
  SpecialGoodsRequestGet,
  SpecialGoodsRequestPost,
  SpecialGoodsRequestPut,
  SpecialGoodsRequestDelete,
  updateSpecialGoods
};
